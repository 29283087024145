import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article2.jpg';
import quilon2 from '../../../img/articles/article2.2.jpg';
import { Cataloge } from './1_Article';
import WhatsApp from '../../WhatsUp/WhatsappLink';
import Karcher from '../../1_Header/Karcher/Karcher';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/ru/article1">EN</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Pranie materacy Gdańsk</h2>

            <p>
              Materac jest jednym z najważniejszych elementów naszej sypialni, a
              zarazem miejscem, w którym spędzamy około jednej trzeciej naszego
              życia. Dlatego ważne jest, aby nasz materac był czysty i
              higieniczny. Regularne pranie materacy jest kluczowe dla
              utrzymania czystości i higieny, a w tym artykule przedstawimy
              kilka powodów, dla których jest to tak istotne.
            </p>
            <p>
              Po pierwsze, pranie materacy pozwala na usunięcie zanieczyszczeń,
              takich jak kurz, roztocza, pleśnie, bakterie i wirusy, które
              gromadzą się w materacu w ciągu czasu. Te zanieczyszczenia mogą
              powodować alergie, astmę i inne choroby, a także wpływać na jakość
              snu. Dlatego ważne jest, aby regularnie prać materac, aby usunąć
              te szkodliwe zanieczyszczenia.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Po drugie, pranie materacy wpływa na estetykę i funkcjonalność
              naszego materaca. Czysty materac wygląda bardziej estetycznie i
              przyczynia się do lepszego samopoczucia w sypialni. Ponadto,
              pranie materacy może również poprawić komfort naszego snu poprzez
              usuwanie plam, zapachów i innych zabrudzeń, które mogą wpływać na
              jakość snu.
            </p>
            <p>
              Po trzecie, pranie materacy może przedłużyć jego żywotność.
              Materace są kosztownymi inwestycjami, dlatego ważne jest, aby dbać
              o ich trwałość. Regularne pranie może pomóc w utrzymaniu czystości
              i higieny materaca, a także w zapobieganiu jego zużyciu. Dzięki
              temu będziemy mogli cieszyć się naszym materacem przez dłuższy
              czas.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              Po czwarte, pranie materacy może pomóc w zwalczaniu nieprzyjemnych
              zapachów. Materace często gromadzą zapachy z potu, jedzenia i
              innych źródeł, które mogą być trudne do usunięcia. Pranie materacy
              pozwala na usunięcie tych zapachów, co pozytywnie wpływa na
              komfort snu i jakość powietrza w naszej sypialni.
            </p>
            <p>
              Podsumowując, pranie materacy jest niezwykle ważne i powinno być
              regularnie przeprowadzane. Dzięki regularnemu praniu zapewniamy
              sobie czystość i higienę naszego materaca, poprawiamy komfort snu,
              przedłużamy żywotność naszego materaca i zapobiegamy rozwojowi
              szkodliwych zanieczyszczeń. Pranie materacy to proste i skuteczne
              rozwiązanie, które pozwoli nam cieszyć się naszym materacem przez
              długi czas.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article2() {
  return (
    <div className="body_wrapper">
      <Header />
      <Karcher />
      <Quilon />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Article2;
