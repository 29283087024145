import './WhatsappLink.css';
import { Link } from 'react-router-dom';
import whatsapp from '../../img/whatsup/whatsapp.png';

function WhatsApp() {
  return (
    <>
      <div className="whatsappLink">
        <Link to="https://wa.me/48888922277">
          <img src={whatsapp} alt="WhatsApp" title="WhatsApp" />
        </Link>
      </div>
    </>
  );
}

// function Viber() {
//   const { isViber, setIsViber } = useProject();
//   useLayoutEffect(() => {
//     if (window.innerWidth < 600) {
//       setIsViber(true);
//     } else {
//       setIsViber(false);
//     }
//   }, [setIsViber]);

//   return (
//     <>
//       <div className={isViber ? 'whatsappLink' : 'hidden'}>
//         <Link to="viber://chat?number=37256680448">
//           <img src={viber} alt="Viber" title="Viber" />
//         </Link>
//       </div>
//     </>
//   );
// }
export default WhatsApp;
