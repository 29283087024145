import VideoPlayer from 'react-player';
import video1 from '../../video/video.mp4';
import video2 from '../../video/video2.mp4';
import video3 from '../../video/video3.mp4';
import video4 from '../../video/video4.mp4';
import video5 from '../../video/video5.mp4';
import video6 from '../../video/video6.mp4';
import video7 from '../../video/newvideo12.mp4';
import videoforarticle from '../../video/newvideo10.mp4';

function ReactVideoPlayer() {
  return (
    <VideoPlayer
      playing={false}
      // style={}
      width="100%"
      height="100%"
      // loop={true}
      controls={true}
      url={video1}
    />
  );
}
function ReactVideoPlayer2() {
  return (
    <VideoPlayer
      playing={false}
      // style={}
      width="100%"
      height="100%"
      // loop={false}
      controls={true}
      url={video2}
    />
  );
}
function ReactVideoPlayer3() {
  return (
    <VideoPlayer
      playing={false}
      // style={}
      width="100%"
      height="100%"
      // loop={false}
      controls={true}
      url={video3}
    />
  );
}
function ReactVideoPlayer4() {
  return (
    <VideoPlayer
      playing={false}
      // style={}
      width="100%"
      height="100%"
      // loop={false}
      controls={true}
      url={video4}
    />
  );
}
function ReactVideoPlayer5() {
  return (
    <VideoPlayer
      playing={false}
      // style={}
      width="100%"
      height="100%"
      // loop={false}
      controls={true}
      url={video5}
    />
  );
}
function ReactVideoPlayer6() {
  return (
    <VideoPlayer
      playing={false}
      // style={}
      width="100%"
      height="100%"
      // loop={false}
      controls={true}
      url={video6}
    />
  );
}
function ReactVideoPlayer7article() {
  return (
    <VideoPlayer
      playing={false}
      // style={}
      width="100%"
      height="100%"
      // loop={false}
      controls={true}
      url={videoforarticle}
    />
  );
}
function ReactVideoPlayer8() {
  return (
    <VideoPlayer
      playing={false}
      // style={}
      width="100%"
      height="100%"
      // loop={false}
      controls={true}
      url={video7}
    />
  );
}

export {
  ReactVideoPlayer,
  ReactVideoPlayer2,
  ReactVideoPlayer3,
  ReactVideoPlayer4,
  ReactVideoPlayer5,
  ReactVideoPlayer6,
  ReactVideoPlayer7article,
  ReactVideoPlayer8,
};
