import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { useLayoutEffect, useState } from 'react';
import {
  ReactVideoPlayer,
  ReactVideoPlayer2,
  ReactVideoPlayer3,
  ReactVideoPlayer4,
  ReactVideoPlayer5,
  ReactVideoPlayer6,
  ReactVideoPlayer8,
} from '../../../UI/ReactVideoPlayer2/ReactVideoPlayer2';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import VideoGallery from '../VideoGallary/Gallery';

// function SwiperVideo() {
//   return (
//     <Swiper
//       // install Swiper modules
//       modules={[Navigation, Pagination, Scrollbar, A11y]}
//       spaceBetween={20}
//       slidesPerView={3}
//       navigation
//       pagination={{ clickable: true }}
//       scrollbar={{ draggable: true }}
//     >
//       <SwiperSlide>
//         <ReactVideoPlayer />
//       </SwiperSlide>
//       <SwiperSlide>
//         <ReactVideoPlayer2 />
//       </SwiperSlide>
//       <SwiperSlide>
//         <ReactVideoPlayer3 />
//       </SwiperSlide>
//       <SwiperSlide>
//         <ReactVideoPlayer4 />
//       </SwiperSlide>
//       <SwiperSlide>
//         <ReactVideoPlayer5 />
//       </SwiperSlide>
//       <SwiperSlide>
//         <ReactVideoPlayer6 />
//       </SwiperSlide>
//       ...
//     </Swiper>
//   );
// }
function SwiperVideo2() {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      <SwiperSlide>
        <ReactVideoPlayer />
      </SwiperSlide>
      <SwiperSlide>
        <ReactVideoPlayer3 />
      </SwiperSlide>
      <SwiperSlide>
        <ReactVideoPlayer4 />
      </SwiperSlide>
      <SwiperSlide>
        <ReactVideoPlayer2 />
      </SwiperSlide>
      <SwiperSlide>
        <ReactVideoPlayer5 />
      </SwiperSlide>
      <SwiperSlide>
        <ReactVideoPlayer6 />
      </SwiperSlide>
      <SwiperSlide>
        <ReactVideoPlayer8 />
      </SwiperSlide>
    </Swiper>
  );
}

function SwiperVideoGallery() {
  const [isHeader, setIsHeader] = useState(false);

  useLayoutEffect(() => {
    if (window.innerWidth < 450) {
      setIsHeader(true);
    } else {
      setIsHeader(false);
    }
  }, [setIsHeader]);
  return (
    <>
      <div className="main__third-services main-second">
        {!isHeader ? (
          <>
            <VideoGallery />
          </>
        ) : (
          <>
            <SwiperVideo2 />
          </>
        )}
      </div>
    </>
  );
}

export default SwiperVideoGallery;
