import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import foto from '../../../img/about/about2.jpg';
import './About.css';
import Karcher from '../../1_Header/Karcher/Karcher';
import WhatsApp from '../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  const closeLang = () => {
    setLang(false);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang closeLang={closeLang} isLang={isLang}>
            {isLang ? (
              <Link to="/ru/about">EN</Link>
            ) : (
              <Link to="/about">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function AboutTable() {
  return (
    <div className="about">
      <div className="about-title">
        <h1>O nas</h1>
      </div>
      <div className="container about-container">
        <div className="about__content">
          <div className="about__content-text">
            <h2>
              Dzień dobry, drodzy klienci, jesteśmy firmą sprzątającą "Fan
              Clean"
            </h2>
            <p>
              Firma <strong>"Fan Clean"</strong> zajmuje niezwykle ważną pozycję
              na rynku, gdyż oferuje usługi niezbędne w życiu codziennym.
              Czyszczenie mebli w domu jest trudnym zadaniem, szczególnie jeśli
              jest w nich dużo brudu lub niebezpiecznych substancji. Dlatego
              warto skorzystać z usług profesjonalistów, którzy wiedzą, jak
              obchodzić się z każdym rodzajem mebli i zadbać o ich prawidłowe
              czyszczenie.
            </p>
            <div className="about__content-img">
              <img src={foto} alt="О нас" />
            </div>
            <p>
              <strong>Profesjonalizm:</strong> Nasza wysoko wykwalifikowana
              kadra posiada bogate doświadczenie w czyszczeniu różnorodnych
              powierzchni, co gwarantuje doskonałe rezultaty.
            </p>
            <p>
              <strong>Dostosowane podejście:</strong> Indywidualnie
              dostosowujemy nasze metody czyszczenia do rodzaju tkanin i stopnia
              zabrudzenia, aby zagwarantować ich najlepszy stan.
            </p>
            <p>
              <strong>Ekologiczne podejście:</strong> Stosujemy przyjazne dla
              środowiska środki czyszczące, które są skuteczne, ale jednocześnie
              nie szkodzą zdrowiu ani naturze.
            </p>
            <p>
              <strong>Technologia:</strong> Wykorzystujemy nowoczesny sprzęt i
              techniki czyszczenia, które pozwalają nam skutecznie usunąć nawet
              najtrudniejsze plamy i zanieczyszczenia.
            </p>
            <p>
              <strong>Szeroki zakres usług:</strong> Nie tylko czyszczymy meble
              i dywany, ale również materace, krzesła, dziecięce wózki, zasłony,
              rolety i wiele innych, zapewniając kompleksową opiekę nad Twoimi
              wnętrzami.
            </p>
            <p>
              <strong>Szybkość i wygoda:</strong> Dostosowujemy się do
              harmonogramu naszych klientów, oferując elastyczne terminy i
              szybką obsługę, aby zapewnić im maksymalną wygodę.
            </p>
            <p>
              Zaufaj naszej firmie, aby Twoje wnętrza zawsze były czyste, świeże
              i przyjemne dla zmysłów!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function About() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <Karcher />

        <AboutTable />
        <WhatsApp />
        <Footer />
      </div>
    </>
  );
}
export default About;
