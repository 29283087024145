import './Services.css';
import main1 from '../../../img/Services/foto15.jpg';
import main4 from '../../../img/Services/foto14.jpg';
import main3 from '../../../img/Services/clean_pillow.jpg';
import main2 from '../../../img/Services/foto17.jpg';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <>
      <div className="main__first-services">
        <div className="main-title">
          <p>PROFESJONALNE PRANIE</p>
          <h1>
            Pranie chemiczne mebli i tapicerki samochodowej, dywanów i
            dywaników, zasłon, sof, krzeseł, fotelików samochodowych dla
            dzieci...
          </h1>
        </div>

        <div className="main__services">
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main1} alt="photo1" />
            </div>
            <h2>Pranie kanap</h2>
            <p className="main-services-price">od 100 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main2} alt="photo1" />
            </div>
            <h2>Pranie foteli</h2>
            <p className="main-services-price">od 15 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main3} alt="photo1" />
            </div>
            <h2>Pranie poduszek</h2>
            <p className="main-services-price">od 5 zł</p>
          </div>
          <div className="main__services-item">
            <div className="main__services-img">
              <img src={main4} alt="photo1" />
            </div>
            <h2>Pranie materaców </h2>
            <p className="main-services-price">od 100 zł</p>
          </div>
        </div>
        <div className="main__link-details">
          <Link to="/price">Szczegółowo...</Link>
        </div>
      </div>
    </>
  );
}
export default Services;
