import css from './Whoops404.module.css';
import { Link } from 'react-router-dom';

function Whoops404() {
  return (
    <div className={css.error404}>
      <h1 className={css.nofindresurs}>
        <span>
          Podana ścieżka jest nieprawidłowa lub nie została znaleziona...
        </span>
      </h1>
      <p>
        Aby przejść do serwisu należy kliknąć w podany link
        <span className={css.linkpath}>
          <Link to="/"> FanClean </Link>
        </span>
      </p>
    </div>
  );
}
export default Whoops404;
