import { Link } from 'react-router-dom';
import './Schedule.css';
import Img_phone from '../../../img/links/calendar.png';
function Schedule() {
  return (
    <div className="header__schedule">
      <div>
        <img
          title="schedule"
          src={Img_phone}
          alt="schedule"
          className="header-schedule-img"
        />
      </div>

      <div>
        <p className="header-schedule-item">6:00-22:00/7</p>
      </div>
    </div>
  );
}
export default Schedule;
