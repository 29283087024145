import './Gallery.css';
import {
  ReactVideoPlayer,
  ReactVideoPlayer2,
  ReactVideoPlayer3,
  ReactVideoPlayer4,
  ReactVideoPlayer5,
  ReactVideoPlayer6,
} from '../../../UI/ReactVideoPlayer2/ReactVideoPlayer2';

function VideoGallery() {
  return (
    <>
      <div className="main__third-services">
        <div className="main__gallery">
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer />
            </div>
          </div>

          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer3 />
            </div>
          </div>
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer4 />
            </div>
          </div>
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer5 />
            </div>
          </div>
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer2 />
            </div>
          </div>
          <div className="main__gallery-item">
            <div className="main__gallery-img">
              <ReactVideoPlayer6 />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default VideoGallery;
