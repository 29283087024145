import { Link } from 'react-router-dom';
import Img_Facebook from '../../../img/links/facebook2.png';
import tiktok from '../../../img/links/tiktok.png';
import instagram from '../../../img/links/instagram.png';

import './LinkSocial.css';

function LinkSocial() {
  return (
    <div className="header__list-social-media">
      <div className="header__social-link-item">
        <Link
          to="https://www.tiktok.com/@fan.clean?_t=8o4CcM2UVqS&_r=1"
          title="tiktok"
          alt="tiktok"
          target="_blank"
          className="header__instargam"
        >
          <img src={tiktok} alt="instagram" />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://www.facebook.com/profile.php?id=61559423638135"
          target="_blank"
          alt="facebook"
          title="facebook"
          className="header_facebook"
        >
          <img src={Img_Facebook} alt="facebook" />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://www.instagram.com/fanclean_gdansk?igsh=aTNyaDdzemkwc25l"
          target="_blank"
          alt="instagram"
          title="instagram"
          className="header_facebook"
        >
          <img src={instagram} alt="facebook" />
        </Link>
      </div>
    </div>
  );
}
export default LinkSocial;
