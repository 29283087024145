import { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Price.css';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import WhatsApp from '../../WhatsUp/WhatsappLink';
import Karcher from '../../1_Header/Karcher/Karcher';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? (
              <Link to="/ru/price">EN</Link>
            ) : (
              <Link to="/price">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

export function PriceTable() {
  return (
    <>
      <div className="price">
        <div className="price-title">
          <h1>Cennik usług:</h1>
        </div>
        <div className="price-padding">
          <div className="outsidewidthtable">
            <p className="lastquestion">
              <span>
                {' '}
                Ceny podane poniżej mają charakter orientacyjny. Cena
                uzależniona jest od stopnia zabrudzenia oraz ilości zamówienia.
                Ostateczną cenę można poznać dzwoniąc do nas lub wysyłając
                zdjęcie tego co wymaga czyszczenia, a nasz pracownik poda
                dokładną wycenę.
              </span>
            </p>
            <table className="table">
              <tbody>
                <tr>
                  <th className="thleft">Nazwa usług</th>
                  <th>Koszt (zl)</th>
                </tr>
                <tr>
                  <td colSpan="2" className="priceOne">
                    CENNIK PRANIA TAPICEREK MEBLOWYCH:
                  </td>
                </tr>

                <tr>
                  <td>pranie kanap</td>
                  <td className="center"> 100</td>
                </tr>
                <tr>
                  <td>pranie i czyszczenie tapicerki samochodowej</td>
                  <td className="center"> 200</td>
                </tr>
                <tr>
                  <td>pranie sof</td>
                  <td className="center"> 100</td>
                </tr>
                <tr>
                  <td>pranie narożników </td>
                  <td className="center">150</td>
                </tr>

                <tr>
                  <td>pranie tapczanów</td>
                  <td className="center">100</td>
                </tr>
                <tr>
                  <td>pranie kompletów wypoczynkowych</td>
                  <td className="center"> 150</td>
                </tr>
                <tr>
                  <td>pranie wersalek</td>
                  <td className="center">80</td>
                </tr>
                <tr>
                  <td>pranie foteli</td>
                  <td className="center">15</td>
                </tr>
                <tr>
                  <td>pranie krzeseł</td>
                  <td className="center"> 10</td>
                </tr>
                <tr>
                  <td>pranie poduszek</td>
                  <td className="center"> 5</td>
                </tr>

                <tr>
                  <td> pranie materaców {<br />} ( 1 strona ) </td>
                  <td className="center"> 100</td>
                </tr>
                <tr>
                  <td>pranie fotelików samochodowych dla dzieci</td>
                  <td className="center"> 50</td>
                </tr>
                <tr>
                  <td>pranie gondoli wózków dziecięcych</td>
                  <td className="center"> 50</td>
                </tr>
                <tr>
                  <td>pranie pluszaków</td>
                  <td className="center"> 10</td>
                </tr>
                <tr>
                  <td colSpan="2" className="priceOne">
                    CENNIK PRANIA WYKŁADZIN DYWANÓW:
                  </td>
                </tr>

                <tr>
                  <td>pranie dywanów (sztucznych)</td>
                  <td className="center"> 20 zł/m2</td>
                </tr>
                <tr>
                  <td>pranie dywanów (wełnianych)</td>
                  <td className="center"> 20 zł/m2</td>
                </tr>
                <tr>
                  <td>pranie wykładzin dywanowych w domach</td>
                  <td className="center"> 10 zł/m2</td>
                </tr>

                <tr>
                  <td>pranie wykładzin dywanowych w biurach</td>
                  <td className="center font">
                    cena do uzgodnienia w zależności od wielkości
                  </td>
                </tr>
                <tr>
                  <td>pranie wykładzin w przedszkolach</td>
                  <td className="center font">
                    cena do uzgodnienia w zależności od wielkości
                  </td>
                </tr>
              </tbody>
            </table>

            <p className="lastquestion">
              <span>
                Uslugi wykonujemy w domu lub mieszkaniu klienta. Stosowana przez
                nas technika czyszczenia to metoda ekstrakcyjna - na mokro. Jest
                to czyszczenie najskuteczniejsze. Do czyszczenia wykorzystujemy
                urządzenia głównie firmy Karcher oraz odpowiednie markowe środki
                chemiczne, które skutecznie usuwają brud a jednocześnie są
                delikatne dla czyszczonych powierzchni oraz bezpieczne dla ludzi
                i zwierząt. <br />
                Zapraszamy do skorzystania z naszych usług!
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

function Price() {
  return (
    <div className="body_wrapper">
      <Header />
      <Karcher />
      <PriceTable />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Price;
