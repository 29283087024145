import SwiperArticles from '../../Swiper/Swiper';
import './Article.css';

function Articles() {
  return (
    <>
      <div className="main__second-services main-second">
        <div className="main-title">
          <p>Polecane artykuły</p>
          <h2>
            Wszystko o praniu chemicznym dywanów, sof, samochodów, myciu okien,
            sprzątaniu pokoi...
          </h2>
        </div>
        <SwiperArticles />
      </div>
    </>
  );
}
export default Articles;
